import React from 'react';

import { useAppSelector } from '@src/hooks';

import { NFTTooltip } from '../NFTTooltip';
import { getPoolMultiplier } from '../utils';

export const NFTBoost: React.FC<{
    id: number;
    className?: string;
    admin?: boolean;
}> = ({ id, className, admin }) => {
    const { pools } = useAppSelector((state) => state.staking);
    const pool = pools.find((p) => p.id === id);
    const { nft_multiplier_used } = pool || {};
    const { boost, has_boost } = getPoolMultiplier(pool, admin);

    return (
        <div className={className}>
            <NFTTooltip id={id}>
                <div
                    className={`max-w-min px-4 py-2 rounded text-center bg-level-two border ${
                        has_boost
                            ? 'border-success'
                            : 'border-primary border-opacity-5 hover:border-opacity-15'
                    }`}
                >
                    <div className="flex items-center space-x-2 justify-center">
                        <div className="rounded-full border border-primary border-opacity-25">
                            <div
                                className={`rounded-full p-2 opacity-100 ${
                                    has_boost ? 'bg-success' : 'bg-main'
                                }`}
                            ></div>
                        </div>
                        <div className="">
                            +{nft_multiplier_used ? boost : '0'}%
                        </div>
                    </div>
                </div>
            </NFTTooltip>
        </div>
    );
};
