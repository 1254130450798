import React from 'react';
import Link from 'next/link';

import { Chevron } from '@src/components/Icon';

export const BackButton: React.FC<{ url: string }> = ({ url }) => {
    return (
        <Link href={url}>
            <a>
                <div className="flex items-center space-x-2 mb-4">
                    <Chevron type="left" />

                    <h5 className="text-lg">Back</h5>
                </div>
            </a>
        </Link>
    );
};
