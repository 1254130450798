import { useEffect, useMemo, useState } from 'react';

import { useAppSelector } from '@src/hooks';
import { BigNumber } from '@ethersproject/bignumber';
import { parseBalance } from '@src/utils/web3';
import { DEFAULT_TOKEN } from '@src/config';

import { aggregateValues, reducePools, getTokenPrices } from './util';
import { cancellablePromise } from '@src/utils/promise';
import { StakingType } from '@src/ts/interfaces';

const { symbol: default_symbol, decimals: default_decimals } = DEFAULT_TOKEN;

export const useStakingAnalytics = () => {
    const { pools } = useAppSelector((state) => state.staking);

    const [prices, setPrices] = useState({});
    const data = useMemo(
        () => reducePools(pools.filter(({ type }) => type !== StakingType.NFT)),
        [pools],
    );
    const token_decimals = pools.reduce(
        (
            acc,
            {
                input_token: { address: i_a, decimals: i_d },
                reward_token: { address: r_a, decimals: r_d },
            },
        ) => ({ ...acc, [i_a]: i_d, [r_a]: r_d }),
        {},
    );

    const token_staked =
        data[default_symbol]?.total_staked || BigNumber.from(0);

    useEffect(() => {
        const { cancel, promise } = cancellablePromise(getTokenPrices(data));

        promise.then((prices) => setPrices(prices)).catch(() => true);

        return cancel;
    }, [data]);

    return useMemo(
        () => [
            {
                title: `Total ${default_symbol} staked`,
                text: `${parseBalance(
                    token_staked,
                    default_decimals,
                )} ${default_symbol}`,
            },
            {
                title: 'Total value locked',
                text: aggregateValues(
                    'total_staked',
                    data,
                    prices,
                    token_decimals,
                ),
            },

            {
                title: 'Your stake',
                text: aggregateValues(
                    'user_stake',
                    data,
                    prices,
                    token_decimals,
                ),
            },
            {
                title: 'Your total rewards',
                text: aggregateValues(
                    'earned_rewards',
                    data,
                    prices,
                    token_decimals,
                ),
            },
        ],
        [
            data,
            prices,
            token_decimals,
            default_symbol,
            default_decimals,
            token_staked,
        ],
    );
};
