import { BigNumberish } from '@ethersproject/bignumber';
import { formatUnits } from '@ethersproject/units';

export const formatLargeNumber = (n: number) => {
    if (n >= 1000000000000) {
        return (n / 1000000000000).toFixed(1) + 'T';
    }
    if (n >= 1000000000) {
        return (n / 1000000000).toFixed(1) + 'B';
    }
    if (n >= 1000000) {
        return (n / 1000000).toFixed(1) + 'M';
    }
    if (n >= 1000) {
        return (n / 1000).toFixed(1) + 'K';
    }
    return n.toFixed(0);
};

export const formatBigNumber = (
    _n: BigNumberish,
    decimals = 18,
    decimals_to_display = 2,
) => {
    const n = Number(formatUnits(_n, decimals));

    if (n >= 1000000000000) {
        return (n / 1000000000000).toFixed(decimals_to_display) + 'T';
    }
    if (n >= 1000000000) {
        return (n / 1000000000).toFixed(decimals_to_display) + 'B';
    }
    if (n >= 1000000) {
        return (n / 1000000).toFixed(decimals_to_display) + 'M';
    }
    if (n >= 1000) {
        return (n / 1000).toFixed(decimals_to_display) + 'K';
    }
    return n.toFixed(decimals_to_display);
};
