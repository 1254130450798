import { ReactNode } from 'react';
import PoolHeader from './PoolHeader';

export const PoolsContainer: React.FC<{
    title: string;
    is_nft?: boolean;
    is_admin?: boolean;
    display_graphic?: boolean;
    has_pools?: boolean;
    button?: ReactNode;
    is_active?: boolean;
}> = ({
    title,
    children,
    is_active,
    is_admin,
    is_nft,
    button,
    display_graphic,
    has_pools,
}) => {
    return (
        <div className="text-primary mb-8 mt-12">
            <div className="flex items-start space-x-4">
                <h2 className="text-3xl mb-8 flex-1">
                    <strong>{title}</strong>
                </h2>
                {button}
            </div>

            {!(is_nft && display_graphic) && (
                <PoolHeader
                    is_active={is_active}
                    is_nft={is_nft}
                    is_admin={is_admin}
                />
            )}
            <div
                className={`${
                    has_pools && is_nft && display_graphic
                        ? 'lg:grid grid-cols-4 gap-4'
                        : ''
                }`}
            >
                {children}
            </div>
        </div>
    );
};
