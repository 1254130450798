import React from 'react';

import { Tooltip } from '@src/components';
import { useAppSelector } from '@src/hooks';

import { effectiveApy } from '@src/utils/staking';
import { StakingType } from '@src/ts/interfaces';
import { getPoolMultiplier } from '../utils';

export const NFTTooltip: React.FC<{ id: number; admin?: boolean }> = ({
    children,
    id,
    admin,
}) => {
    const { pools } = useAppSelector((state) => state.staking);

    const pool = pools.find((p) => p.id === id);

    const { reward_rate, nft_multiplier_used, nft_name, type } = pool || {};

    const { boost, has_boost } = getPoolMultiplier(pool, admin);

    const APR_with_boost =
        type === StakingType.Compound
            ? (
                  effectiveApy(parseFloat(reward_rate) / 100, 365) * 100 +
                  boost
              ).toFixed(2) + '%'
            : (parseFloat(reward_rate) + boost).toFixed(2) + '%';

    return (
        <Tooltip
            text={`<div class="text-primary p-1"><div class="flex space-x-4"><div class="flex-1 text-secondary">Required NFT</div><div class="${
                has_boost ? 'text-success' : ''
            }"><strong>${
                (nft_multiplier_used && nft_name) || 'None'
            }</strong></div></div><div class="flex space-x-4 mt-2"><div class="flex-1 text-secondary">APY with boost</div><div class="${
                has_boost ? 'text-success' : ''
            }"><strong>${
                (nft_multiplier_used && APR_with_boost) || '0%'
            }</strong></div></div></div>`}
        >
            {children}
        </Tooltip>
    );
};
