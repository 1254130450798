import React, { useState } from 'react';

import { ProgressBar } from '@src/components/ProgressBar';
import { parseBalance } from '@src/utils/web3';
import { NFTStakingPool, StakingPool, StakingType } from '@src/ts/interfaces';

import { Icon } from '../../Icon';
import { NFTBoost } from '../../NFTBoost';
import { usePoolItem } from './hooks';
import { Loader } from '@src/components/Layout';

export const DesktopPoolItem: React.FC<{
    pool: StakingPool;
    admin?: boolean;
}> = ({ pool, admin }) => {
    const {
        APR,
        decimals,
        expired,
        handleClick,
        hardcap,
        has_stake,
        id,
        is_compound,
        is_nft,
        is_liquidity,
        loading,
        stake,
        user_stake,
        symbol,
        total_staked,
        reward_rate,
        pool_time,
        lock_period,
        second_input_token,
        second_total_staked,
        second_user_stake,
    } = usePoolItem(pool, admin);

    const [loaded, setLoaded] = useState(false);
    const logo =
        pool.type === StakingType.NFT ? (pool as NFTStakingPool).logo : '-';
    const max_per_user = (pool as NFTStakingPool).max_per_user;

    return (
        <div
            className={`my-4 w-full text-left grid grid-cols-12 rounded border border-custom cursor-pointer bg-level-one text-primary items-center text-left ${
                loading ? 'animate-pulse' : ''
            } ${logo ? '' : 'border-accent'}`}
            onClick={handleClick}
            data-testid="staking-pool"
        >
            {is_nft ? (
                <div
                    className={`col-span-4 flex items-center space-x-6 ${pool}`}
                >
                    {logo ? (
                        <img
                            src={logo}
                            alt={pool.name}
                            className={`h-24 rounded-l ${
                                loaded
                                    ? ''
                                    : 'bg-main animate-pulse aspect-square'
                            }`}
                            onLoad={() => setLoaded(true)}
                        />
                    ) : (
                        <div className="w-24 h-24 bg-main flex items-center rounded-l">
                            <Loader height={10} />
                        </div>
                    )}
                    <h3 className={`text-lg ${pool.name ? '' : 'text-accent'}`}>
                        <strong>{pool.name || 'Pool name'}</strong>
                    </h3>
                </div>
            ) : (
                <Icon pool={pool} className="p-2 col-span-4" />
            )}

            <div className={`p-2 col-span-${is_nft ? 2 : 1}`}>
                <h1 className="text-2xl">
                    <strong>
                        {is_nft
                            ? `${parseBalance(reward_rate)} ${
                                  pool.reward_token.symbol
                              }`
                            : APR}
                    </strong>
                </h1>
                {is_compound && (
                    <p className="text-sm text-secondary">Compounding</p>
                )}
                {is_nft && (
                    <p className="text-sm text-secondary">Daily per NFT</p>
                )}
            </div>

            {!is_nft && (
                <NFTBoost
                    id={id}
                    className="p-2 col-span-2 mx-auto"
                    admin={admin}
                />
            )}
            {is_nft && admin && (
                <div
                    className={`col-span-2 ${
                        max_per_user ? '' : 'text-accent'
                    }`}
                >
                    <h3 className="text-2xl">{max_per_user}</h3>
                    <p
                        className={`${
                            max_per_user ? 'text-secondary' : 'text-accent'
                        }`}
                    >
                        NFTs per wallet
                    </p>
                </div>
            )}

            <div className={`p-2 col-span-${admin || is_nft ? 2 : 1}`}>
                <p>{pool_time}</p>
                {admin && (
                    <p className="text-secondary">{lock_period} days lock</p>
                )}
            </div>
            <div className={`p-2 col-span-2 pl-8`}>
                {((admin || !expired) && (
                    <ProgressBar
                        hardcap={hardcap}
                        total_staked={total_staked}
                        second_total_staked={second_total_staked}
                        decimals={is_nft ? 0 : decimals}
                        second_decimals={
                            is_nft ? 0 : second_input_token?.decimals
                        }
                        symbol={symbol}
                        second_symbol={second_input_token?.symbol}
                        liquidity={is_liquidity}
                    />
                )) || <p>Pool expired</p>}
            </div>
            {has_stake && !admin && (
                <div className={`p-2 pr-4 col-span-2 text-right`}>
                    <p>
                        {is_nft
                            ? `${user_stake} / ${
                                  (pool as NFTStakingPool).max_per_user
                              }`
                            : `${parseBalance(stake, decimals)} ${symbol}`}
                        {is_liquidity && (
                            <p>
                                {parseBalance(
                                    second_user_stake,
                                    second_input_token.decimals,
                                )}{' '}
                                {second_input_token.symbol}
                            </p>
                        )}
                    </p>
                </div>
            )}
        </div>
    );
};
