import React from 'react';

import { ProgressBar } from '@src/components/ProgressBar';
import { parseBalance } from '@src/utils/web3';
import { StakingPool } from '@src/ts/interfaces';

import { Icon } from '../../Icon';
import { usePoolItem } from './hooks';

export const MobilePoolItem: React.FC<{
    pool: StakingPool;
    admin?: boolean;
}> = ({ pool, admin }) => {
    const {
        APR,
        boost,
        decimals,
        expired,
        handleClick,
        hardcap,
        has_boost,
        has_stake,
        loading,
        stake,
        symbol,
        total_staked,
        pool_time,
        is_liquidity,
        nft_multiplier_used,
        second_input_token,
        second_total_staked,
        second_user_stake,
    } = usePoolItem(pool, admin);

    return (
        <div
            className={`my-4 w-full text-left rounded border border-custom cursor-pointer bg-level-one text-primary ${
                loading ? 'animate-pulse' : ''
            }`}
            onClick={handleClick}
        >
            <div className={`w-full bg-level-two p-5 rounded space-y-4`}>
                <Icon pool={pool} dark={true} className="col-span-4" />

                {(!expired && (
                    <ProgressBar
                        hardcap={hardcap}
                        total_staked={total_staked}
                        second_total_staked={second_total_staked}
                        decimals={decimals}
                        second_decimals={second_input_token?.decimals}
                        symbol={symbol}
                        second_symbol={second_input_token?.symbol}
                        liquidity={is_liquidity}
                    />
                )) || (
                    <div className="flex my-2">
                        <div className="flex-1 text-secondary">Capacity</div>
                        Pool expired
                    </div>
                )}
            </div>
            <div className="p-5">
                <div className="flex my-2">
                    <div className="flex-1 text-secondary">
                        {admin ? 'Pool expiry date' : 'Lock term'}
                    </div>

                    <div>{pool_time}</div>
                </div>
                <div className="flex my-2">
                    <div className="flex-1 text-secondary">Reward</div>

                    <div>{APR}</div>
                </div>

                <div className="flex my-2">
                    <div className="flex-1 text-secondary">NFT boost</div>

                    <div
                        className={
                            (admin && nft_multiplier_used) || has_boost
                                ? 'text-success'
                                : ''
                        }
                    >
                        +{boost}%
                    </div>
                </div>

                {has_stake && (
                    <>
                        <div className="flex my-2">
                            <div className="flex-1 text-secondary">
                                Your stake
                            </div>
                            <div className="text-right">
                                <p>
                                    {parseBalance(stake, decimals)} {symbol}
                                </p>
                                {is_liquidity && (
                                    <p>
                                        {parseBalance(
                                            second_user_stake,
                                            second_input_token.decimals,
                                        )}{' '}
                                        {second_input_token.symbol}
                                    </p>
                                )}
                            </div>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};
