import { BigNumber } from '@ethersproject/bignumber';
import { formatUnits } from '@ethersproject/units';

import { StakingPool, StakingType } from '@src/ts/interfaces';
import { effectiveApy } from '@src/utils/staking';

export const getReward = (p: StakingPool) => {
    if (p.type === StakingType.NFT)
        return Number(formatUnits(p.reward_rate, p.reward_token.decimals));

    const {
        reward_rate,
        nft_multiplier = 10,
        nft_multiplier_used,
        user_multiplier = 10,
        type,
    } = p;

    const APR =
        type === StakingType.Compound
            ? effectiveApy(parseFloat(reward_rate) / 100, 365) * 100
            : parseFloat(reward_rate);

    const boost = Math.round((parseFloat(reward_rate) * nft_multiplier) / 10);

    const has_boost =
        nft_multiplier_used &&
        BigNumber.from(user_multiplier).eq(nft_multiplier);

    return (has_boost && boost) || APR;
};
