import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

import { StakingPool } from '@src/ts/interfaces';

dayjs.extend(timezone);
dayjs.extend(utc);

export const getCorrectPoolTime = (
    pool: StakingPool,
    admin: boolean,
    lock_end_date: string,
) => {
    if (admin)
        return (
            dayjs(pool.end_date).utc(false).format('DD/MM/YY HH:mm') + ' UTC'
        );

    if (pool.has_stake) {
        return pool.can_claim ? 'Unlocked' : lock_end_date;
    }

    return pool.lock_period + ' days';
};
