import React from 'react';

import { InfoText } from '../..';

const PoolHeader: React.FC<{
    is_active?: boolean;
    is_nft?: boolean;
    is_admin?: boolean;
}> = ({ is_active, is_nft, is_admin }) => {
    return (
        <div className="hidden w-full items-center px-2 py-1 text-secondary lg:grid grid-cols-12 text-left">
            <div className={`text-left col-span-4`}>Pools</div>
            <div className={`col-span-${is_nft ? 2 : 1}`}>
                <InfoText
                    info={
                        is_nft
                            ? 'The estimated daily reward per NFT staked in the pool.'
                            : 'The estimated yearly returns made from the pool.'
                    }
                    text="Reward"
                    text_color="text-secondary"
                />
            </div>
            {!is_nft && (
                <div className="col-span-2 mx-auto">
                    <InfoText
                        info="Specified APY boost applies if you own an appropriate NFT"
                        text="NFT boost"
                        text_color="text-secondary"
                    />
                </div>
            )}
            {is_nft && is_admin && (
                <div className={`col-span-2`}>Max stake</div>
            )}
            <div className={`col-span-${is_admin || is_nft ? 2 : 1}`}>
                {is_admin ? 'Expiry & lock term' : 'Lock term'}
            </div>
            <div className="col-span-2 pl-8">Capacity</div>
            {is_active && !is_admin && (
                <div className={`col-span-2 text-right`}>Your stake</div>
            )}
        </div>
    );
};

export default PoolHeader;
