import React, { useEffect, useState } from 'react';
import { isAddress } from '@ethersproject/address';

import { Input } from '@src/components/Input';
import { Select } from '@src/components/Select';
import { getContractByAddress } from '@src/contracts';
import {
    CompoundStakingPool,
    NFTStakingPool,
    StakingPool,
    StakingType,
} from '@src/ts/interfaces';

import { ContractType } from '@src/ts/constants';
import dayjs from 'dayjs';
import { parseBalance } from '@src/utils/web3';
import { cancellablePromise } from '@src/utils/promise';

import { getPoolLabel } from './util';
import { contract_types } from '@src/constants';

const last_deposit_field = {
    [StakingType.Compound]: 'lastDepositedTime',
    [StakingType.NFT]: 'depositTime',
};

export const WalletLookUp: React.FC<{ pools: StakingPool[] }> = ({ pools }) => {
    const [selected, setSelected] = useState('0');
    const [wallet, setWallet] = useState('');
    const [loading, setLoading] = useState(false);

    const [user, setUser] = useState(null);

    const pool = pools.find(({ id }) => id.toString() === selected);
    const { address, contract_idx, lock_period, input_token, type } =
        pool || {};
    const staking = getContractByAddress(address, contract_types[type]);
    const compounder = getContractByAddress(
        (pool as CompoundStakingPool)?.vault,
        ContractType.Vault,
    );
    const is_nft = type === StakingType.NFT;
    const contract_to_use =
        type === StakingType.Compound ? compounder : staking;

    useEffect(() => {
        if (address && isAddress(wallet)) {
            setLoading(true);
            // eslint-disable-next-line
            const p = contract_to_use
                .users(contract_idx, wallet)
                .then(async (res) => {
                    const multiplier = await staking.calcMultiplier(
                        contract_idx,
                        wallet,
                    );
                    const last_depo = res[last_deposit_field[type]];
                    const user = {
                        stake: res.totalInvested,
                        unlock_date:
                            (last_depo.toNumber
                                ? last_depo.toNumber()
                                : last_depo) +
                            parseInt(lock_period) * 86400,
                        multiplier: multiplier !== 10 && multiplier !== 100,
                    };
                    return user;
                });

            const { promise, cancel } = cancellablePromise(p);

            promise
                .then((user) => {
                    setUser(user);
                    setLoading(false);
                })
                .catch(() => {
                    setLoading(false);
                });

            return cancel;
        } else {
            setUser(null);
        }
    }, [address, wallet]);

    return (
        <div className="max-w-2xl mx-auto my-20">
            <h3 className="text-xl mb-8">
                <strong>Wallet lookup</strong>
            </h3>

            <div className="p-4 rounded bg-level-one border border-custom">
                <Select
                    options={pools}
                    selected={selected}
                    getId={(pool: StakingPool) => pool.id.toString()}
                    getLabel={getPoolLabel}
                    onSelect={(id) => setSelected(id.toString())}
                    label={'Pool'}
                    loading={loading}
                    placeholder="Select pool"
                    className="my-2"
                    id="staking-pool"
                />
                <Input
                    id="wallet-address"
                    label={'Wallet address'}
                    placeholder="Enter wallet address"
                    name="wallet"
                    className="mb-2 mt-4"
                    value={wallet}
                    onChange={(e) => setWallet(e.target.value)}
                />

                {user && (
                    <div className="mt-8" data-testid="wallet-lookup-result">
                        <h5 className="text-lg mb-4">
                            <strong>Overview</strong>
                        </h5>

                        <div className="flex items-center my-1">
                            <div className="flex-1 text-secondary">
                                Total stake
                            </div>
                            <h5 className="text-lg">
                                <strong>
                                    {is_nft
                                        ? `${user.stake} / ${
                                              (pool as NFTStakingPool)
                                                  .max_per_user
                                          }`
                                        : `${parseBalance(user.stake)} ${
                                              input_token.symbol
                                          }`}
                                </strong>
                            </h5>
                        </div>
                        <div className="flex items-center my-1">
                            <div className="flex-1 text-secondary">
                                Unlock date
                            </div>
                            <h5 className="text-lg">
                                <strong>
                                    {user.stake.gt(0)
                                        ? dayjs(user.unlock_date * 1000).format(
                                              'DD/MM/YY HH:mm',
                                          )
                                        : 'None'}
                                </strong>
                            </h5>
                        </div>
                        <div className="flex items-center my-1">
                            <div className="flex-1 text-secondary">
                                NFT boost
                            </div>
                            <h5 className="text-lg">
                                <strong>{user.multiplier.toString()}</strong>
                            </h5>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};
