export const AnalyticsTextItem: React.FC<{ title: string }> = ({
    children,
    title,
}) => (
    <div>
        <h3 className="text-xl mb-1">
            <strong>{children}</strong>
        </h3>
        <p className="text-xs text-secondary">{title.toUpperCase()}</p>
    </div>
);
