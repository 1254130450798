import { STAKING_MODULES } from '@src/config';
import { HAS_BOTH_STAKING_LAYOUTS, TOKEN_ONLY } from '@src/constants';

import { ModuleWithIdx, StakingType } from '@src/ts/interfaces';

export const effectiveApy = (r: number, n: number): number =>
    (1 + r / n) ** n - 1;

export const getAddressOptions = (type: StakingType) =>
    STAKING_MODULES.filter((m) => type === m.type).map((m, idx) => ({
        ...m,
        idx,
    })) as ModuleWithIdx[];

export const getInitialStakingType = () => {
    if (HAS_BOTH_STAKING_LAYOUTS || TOKEN_ONLY) {
        return 0;
    }
    return 1;
};
