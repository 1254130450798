import { useState, useEffect, useMemo } from 'react';
import { useRouter } from 'next/router';
import { toast } from 'react-toastify';

import { Input } from '@src/components/Input';
import { Button } from '@src/components/Button';
import { getNFTCustomisationFormData, validateInput } from '@src/utils/form';

import { useDecubateContractByAddress } from '@src/hooks/useContract';
import { Dictionary, StakingType } from '@src/ts/interfaces';

import { getPathname } from '@src/utils/getters';
import { useNFT } from '@src/hooks/useNFT';
import { contract_types } from '@src/constants';

import { RequiresSetup } from '../RequiresSetup';

const Inset = (text: string) => (
    <div className="px-2 w-28">
        <strong>{text}</strong>
    </div>
);

const getCustomisationParams = (
    idx: string,
    {
        name,
        logo,
        header_logo,
        start_idx,
        end_idx,
        max_per_user,
        collection_url,
    }: Dictionary,
) => [
    idx,
    name,
    logo,
    header_logo,
    collection_url,
    start_idx,
    end_idx,
    max_per_user,
];

export const NFTCustomisation: React.FC<{
    add?: boolean;
    back: () => void;
    state: Dictionary;
    handleChange: (name: string, value: string | boolean) => void;
    customisation_has_changes: boolean;
    input_token: string;
    idx: string;
    address: string;
    type: StakingType;
}> = ({
    idx,
    add,
    back,
    state = {},
    handleChange,
    input_token,
    customisation_has_changes,
    address,
    type,
}) => {
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);

    const router = useRouter();

    const [staking] = useDecubateContractByAddress(
        address as string,
        contract_types[type as StakingType],
        true,
    );

    const form_data = useMemo(() => getNFTCustomisationFormData(), []);

    const { name, total_supply, nft_loading } = useNFT(input_token);

    useEffect(() => {
        if (!state.name && handleChange) {
            handleChange('name', name);
            handleChange('start_idx', '0');
            handleChange('end_idx', total_supply?.toString() || '0');
        }
    }, [name, total_supply, handleChange]);

    const handleSave = async () => {
        setErrors({});
        const { errors, has_required_fields } = validateInput(form_data, state);
        if (!has_required_fields) {
            setErrors(errors);
            return;
        }

        setLoading(true);
        try {
            toast.info('Awaiting successful transaction');

            const params = getCustomisationParams(idx, state);
            const tx = await staking.setNFTInfo(...params);
            await tx.wait();

            toast.success('Successfully updated NFT collection values');
            setLoading(false);
            router.push({
                pathname: getPathname(router.asPath),
                query: { is_second_form: true },
            });
        } catch (err) {
            toast.error(err.message);
            setLoading(false);
        }
    };

    if (add) {
        return (
            <RequiresSetup
                text="You need to set up and create a pool before you can customise the collection settings"
                back={back}
            />
        );
    }
    return (
        <div className="mt-8">
            {form_data.map(
                ({
                    name,
                    label,
                    type,
                    placeholder,
                    tooltip,
                    inset,
                    step,
                    min,
                }) => (
                    <Input
                        className={`my-4`}
                        key={name}
                        value={
                            state[name] as string | number | readonly string[]
                        }
                        loading={name === 'contract' && nft_loading}
                        name={name}
                        id={name}
                        label={label}
                        error={errors[name]}
                        onChange={({
                            target: { type, name, value, checked },
                        }) =>
                            handleChange(
                                name,
                                type === 'checkbox' ? checked : value,
                            )
                        }
                        placeholder={placeholder}
                        type={type}
                        inset={inset && Inset(inset)}
                        tooltip={tooltip}
                        step={step}
                        min={min}
                    />
                ),
            )}

            <div className="flex space-x-4 items-center mt-8">
                <div className="flex-1">
                    <Button
                        dark
                        onClick={back}
                        className="w-full"
                        id="back_button"
                    >
                        Back
                    </Button>
                </div>
                <div className="flex-1">
                    <Button
                        onClick={handleSave}
                        disabled={!customisation_has_changes || loading}
                        loading={loading}
                        className="w-full"
                        id="save_changes"
                    >
                        Save changes
                    </Button>
                </div>
            </div>
        </div>
    );
};
