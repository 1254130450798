import { ViewListIcon, CollectionIcon } from '@heroicons/react/solid';

export const DisplayButtons: React.FC<{
    display_graphic: boolean;
    setDisplayGraphic: (val: boolean) => void;
}> = ({ display_graphic, setDisplayGraphic }) => (
    <div className="flex items-center space-x-2">
        <button
            className={`text-white bg-level-one rounded border border-custom p-2 ${
                display_graphic ? 'bg-level-one' : 'bg-level-two'
            }`}
            onClick={() => setDisplayGraphic(false)}
        >
            <ViewListIcon className="text-primary w-6 h-6" />
        </button>
        <button
            className={`text-white bg-level-one rounded border border-custom p-2 ${
                display_graphic ? 'bg-level-two' : 'bg-level-one'
            }`}
            onClick={() => setDisplayGraphic(true)}
        >
            <CollectionIcon className="text-primary w-6 h-6" />
        </button>
    </div>
);
