import React from 'react';

import { Button, Input, Select } from '@src/components';
import { ModuleWithIdx, StakingModule } from '@src/ts/interfaces';
import { shortenHex } from '@src/utils/web3';

import { useBountySetup } from './hooks';

export const BountySetup: React.FC = () => {
    const {
        address,
        changed,
        error,
        handleClick,
        loading,
        opts,
        percent,
        setAddress,
        setPercent,
    } = useBountySetup();

    return (
        <div className="max-w-2xl mx-auto my-20">
            <h3 className="text-xl mb-8">
                <strong>Bounty fee setup</strong>
            </h3>
            <div className="p-4 rounded bg-level-one border border-custom">
                {opts.length > 1 && (
                    <Select
                        options={opts}
                        selected={address as string}
                        getId={(m: StakingModule) => m.address}
                        getLabel={(m: ModuleWithIdx) =>
                            `${m.type} ${m.idx + 1} - ${shortenHex(
                                m.address,
                                4,
                            )} ${m.idx === opts.length - 1 ? '(latest)' : ''}`
                        }
                        onSelect={(id) => setAddress(id)}
                        label={'Staking contract'}
                        loading={loading}
                        placeholder="Select pool"
                        className="my-2"
                        id="staking-pool"
                    />
                )}
                <Input
                    value={percent}
                    onChange={(e) => setPercent(e.target.value)}
                    label="Bounty percentage"
                    name="bounty-percentage"
                    inset={<div className="px-4">Percent</div>}
                    tooltip="The percentage of rewards earned so far that have not been compounded, which will be given to the bounty claimer. Supports 2 decimal places"
                    error={error}
                    type="number"
                    step={0.05}
                    id="set_bounty"
                />

                <Button
                    onClick={handleClick}
                    className="mt-4 w-full"
                    disabled={!changed || loading}
                    loading={loading}
                    id="save_bounty"
                >
                    Save changes
                </Button>
            </div>
        </div>
    );
};
