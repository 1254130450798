import { StakingType } from '@src/ts/interfaces';

export const unstake_field = {
    [StakingType.Compound]: 'user_shares',
    [StakingType.Liquidity]: 'lp_amount',
};

export const STAKE_METHOD = {
    [StakingType.Compound]: 'deposit',
    [StakingType.NFT]: 'stake',
    [StakingType.Liquidity]: 'addLiquidityAndLock',
};

export const UNSTAKE_METHOD = {
    [StakingType.Compound]: 'withdraw',
    [StakingType.NFT]: 'unStake',
    [StakingType.Liquidity]: 'unlockAndRemoveLP',
};
