import React from 'react';

export const Progress: React.FC<{
    percent: number;
    marker?: number;
    height?: string;
}> = ({ percent, marker, height = 'h-3' }) => {
    return (
        <div
            className={`overflow-hidden text-xs flex rounded-full relative ${height} bg-main border border-white border-opacity-5`}
        >
            {marker && (
                <div
                    className={`absolute w-1 bg-white ${height}`}
                    style={{ left: `${marker}%` }}
                >
                    <div className="absolute text-base flex h-full items-center right-4">
                        <strong>MIN</strong>
                    </div>
                </div>
            )}
            <div
                style={{ width: `${percent}%` }}
                className={`rounded-full bg-accent`}
            ></div>
        </div>
    );
};
