import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { useDecubateContractByAddress } from '@src/hooks/useContract';
import { ContractType } from '@src/ts/constants';
import { BigNumber } from '@ethersproject/bignumber';
import { cancellablePromise } from '@src/utils/promise';
import { CompoundStakingModule, StakingType } from '@src/ts/interfaces';
import { getAddressOptions } from '@src/utils/staking';

const opts = getAddressOptions(StakingType.Compound);

export const useBountySetup = () => {
    const [original, setOriginal] = useState('0');
    const [percent, setPercent] = useState('0');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [address, setAddress] = useState(opts[opts.length - 1].address);

    const { vault } = opts.find(
        (o) => o.address === address,
    ) as unknown as CompoundStakingModule;

    const [compounder] = useDecubateContractByAddress(
        vault,
        ContractType.Vault,
        true,
    );
    const changed = percent !== original;

    useEffect(() => {
        if (compounder) {
            setLoading(true);
            const p = compounder.callFee().then(async (fee: BigNumber) => {
                const parsed = (fee.toNumber() / 100).toString();
                return parsed;
            });
            const { promise, cancel } = cancellablePromise(p);

            promise
                .then((parsed: string) => {
                    setPercent(parsed);
                    setOriginal(parsed);
                    setLoading(false);
                })
                .catch(() => true);

            return cancel;
        }
    }, [compounder]);

    const handleClick = async () => {
        setError('');
        if ([null, undefined, ''].includes(percent)) {
            setError('required');
            return;
        }
        setLoading(true);
        try {
            toast.info('Awaiting succesful transaction');

            const tx = await compounder.setCallFee(
                Math.round(Number(percent) * 100),
            );

            await tx.wait();

            toast.success('Successfully updated the bounty values');
        } catch (err) {
            toast.error(err);
        }
        setLoading(false);
    };

    return {
        address,
        setAddress,
        loading,
        percent,
        setPercent,
        error,
        handleClick,
        changed,
        opts,
    };
};
