import React from 'react';

import { SelectProps } from '@src/ts/props';

import { IS_DARK_MODE } from '@src/config';
import { Loader } from '..';

export const Select: React.FC<SelectProps> = ({
    id,
    className,
    label,
    onSelect,
    selected,
    options,
    error,
    placeholder,
    getId = (i: string) => i,
    getLabel = (i: string) => i,
    disabled,
    loading,
}) => {
    const container_classes = `flex items-center space-x-2 py-3 px-3 bg-main focus:outline-none rounded border border-custom `;
    const input_classes = `focus:outline-none bg-main flex-1 w-full dark-placeholder  ${
        IS_DARK_MODE ? 'dark-mode' : ''
    }`;

    return (
        <div className={`w-full ${className || ''}`}>
            <div className="flex items-cenetr">
                {label && (
                    <label className="text-sm text-secondary mb-2" htmlFor={id}>
                        {label}
                    </label>
                )}
                {error && <p className="text-red">{error}</p>}
            </div>

            <div className={container_classes}>
                <select
                    id={id}
                    onChange={(e) => onSelect(e.target.value)}
                    className={input_classes}
                    value={selected}
                    disabled={disabled}
                >
                    <option value="" disabled>
                        {placeholder}
                    </option>

                    {options.map((item) => (
                        <option
                            key={getId(item)}
                            id={getId(item)}
                            value={getId(item)}
                        >
                            {getLabel(item)}
                        </option>
                    ))}
                </select>
                <div className="w-4">
                    {loading && <Loader height={5} className="mx-auto" />}
                </div>
            </div>
        </div>
    );
};
