import { useMemo } from 'react';
import dayjs from 'dayjs';
import { BigNumber } from '@ethersproject/bignumber';

import { useAppDispatch, useAppSelector } from '@src/hooks';
import {
    setCurrentPool,
    setModalState,
    setStakingDrawerOpen,
} from '@src/features/staking';
import { DEFAULT_TOKEN } from '@src/config';
import { effectiveApy } from '@src/utils/staking';
import {
    LiquidityStakingPool,
    StakingPool,
    StakingType,
} from '@src/ts/interfaces';

import { parseBalance } from '@src/utils/web3';
import { NFTStakingPool } from '@src/ts/interfaces';
import { formatLargeNumber } from '@src/utils/format';
import { useRouter } from 'next/router';
import { getCorrectPoolTime } from './utils';
import { getPoolMultiplier } from '../../utils';

const { symbol: default_symbol, decimals: default_decimals } = DEFAULT_TOKEN;

export const usePoolItem = (pool: StakingPool, admin: boolean) => {
    const {
        reward_rate = '0',
        total_staked = '0',
        hardcap,
        input_token,
        has_stake,
        id,
        can_claim,
        user_stake = '0',
        lock_period,
        earned_reward = '0',
        last_user_deposit,
        nft_multiplier_used,
        end_date,
        contract_idx,
        address,
        type,
    } = pool;

    const { second_total_staked, second_input_token, second_user_stake } =
        pool as LiquidityStakingPool;

    const dispatch = useAppDispatch();
    const router = useRouter();
    const { loading } = useAppSelector((state) => state.staking);

    const { decimals = default_decimals, symbol = default_symbol } =
        input_token;

    const is_compound = type === StakingType.Compound;
    const is_nft = type === StakingType.NFT;
    const is_liquidity = type === StakingType.Liquidity;

    const APR =
        type === StakingType.Compound
            ? (effectiveApy(parseFloat(reward_rate) / 100, 365) * 100).toFixed(
                  2,
              ) + '%'
            : reward_rate;

    const lock_end_date = dayjs(last_user_deposit)
        .add(Number(lock_period), 'days')
        .format('MMM DD, YYYY');

    const stake = BigNumber.from(user_stake).add(earned_reward);
    const pool_time = useMemo(
        () => getCorrectPoolTime(pool, admin, lock_end_date),
        [pool, admin, lock_end_date],
    );

    const expired = dayjs(end_date).diff(dayjs()) <= 0;

    const { boost, has_boost } = getPoolMultiplier(pool, admin);

    const handleClick = () => {
        if (admin) return router.push(`/manage/${contract_idx}/${address}`);
        if (has_stake && can_claim) {
            dispatch(setModalState('Withdraw'));
        }
        dispatch(setStakingDrawerOpen(true));
        dispatch(setCurrentPool(id));
    };

    return {
        APR,
        boost,
        can_claim,
        decimals,
        expired,
        user_stake,
        handleClick,
        hardcap,
        has_boost,
        has_stake,
        id,
        is_compound,
        is_nft,
        is_liquidity,
        lock_period,
        loading,
        lock_end_date,
        stake,
        symbol,
        total_staked,
        reward_rate,
        pool_time,
        nft_multiplier_used,
        second_total_staked,
        second_input_token,
        second_user_stake,
    };
};

export const useInfoItems = (
    {
        has_stake,
        user_stake,
        max_per_user,
        total_staked,
        hardcap,
        reward_rate,
        reward_token,
        lock_period,
    }: NFTStakingPool,
    lock_end_date: string,
    pool_date: string,
    admin: boolean,
) =>
    useMemo(
        () => [
            {
                text: pool_date,
                title: admin ? 'Pool expiry date' : 'Lock term',
            },
            {
                text: `${parseBalance(reward_rate)} ${reward_token.symbol}`,
                title: 'Daily per NFT',
            },
            {
                text: `${formatLargeNumber(
                    Number(total_staked),
                )} / ${formatLargeNumber(Number(hardcap))}`,
                title: 'Total staked',
            },
            {
                text: has_stake
                    ? `${formatLargeNumber(
                          Number(user_stake),
                      )} / ${formatLargeNumber(Number(max_per_user))}`
                    : max_per_user,
                title: has_stake ? 'Your stake' : 'Max stake',
            },
        ],
        [
            has_stake,
            user_stake,
            max_per_user,
            total_staked,
            hardcap,
            reward_rate,
            reward_token,
            lock_period,
            lock_end_date,
        ],
    );
