export const Switch: React.FC<{
    curr: number;
    setCurr: (v: number) => void;
    opts: string[];
    className?: string;
    dark?: boolean;
}> = ({ curr, setCurr, opts, className, dark }) => {
    const main_color = dark ? 'main' : 'level-one';
    const active_color = dark ? 'level-one' : 'level-two';
    const border_color = dark ? 'border-main' : 'border-level-one';

    return (
        <div className={`border border-custom rounded ${className}`}>
            <div className={`rounded border ${border_color}`}>
                <div
                    className={`p-0 bg-${main_color} border-2 ${border_color} flex items-center rounded`}
                >
                    {opts.map((o, idx) => (
                        <button
                            key={o}
                            data-testid={`switch-option:${o}`}
                            onClick={() => setCurr(idx)}
                            className={`rounded flex-1 px-4 py-2 ${
                                idx === curr
                                    ? `bg-${active_color} border border-custom`
                                    : `bg-${main_color} text-secondary`
                            } `}
                        >
                            {o}
                        </button>
                    ))}
                </div>
            </div>
        </div>
    );
};
