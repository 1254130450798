import {
    LiquidityStakingPool,
    StakingPool,
    StakingType,
} from '@src/ts/interfaces';

const IconPart: React.FC<{
    img: string;
    symbol: string;
    action: string;
    dark: boolean;
}> = ({ img, symbol, action, dark }) => {
    return (
        <div
            className={`px-3 py-2 rounded-lg flex border-border-white border-opacity-5 space-x-2 ${
                dark ? 'bg-level-three' : 'bg-level-two'
            }`}
        >
            <img
                data-testid="staking-icon-img"
                src={img}
                alt={symbol}
                height={40}
                width={40}
                className="w-12 h-12"
            />
            <div>
                <h2 className="lg:text-xl">
                    <strong data-testid="icon-symbol">{symbol}</strong>
                </h2>
                <p className="text-secondary text-xs">{action.toUpperCase()}</p>
            </div>
        </div>
    );
};

const LiquidityIconPart: React.FC<{
    pool: LiquidityStakingPool;
    dark: boolean;
}> = ({ pool, dark }) => {
    const { input_token, second_input_token } = pool;

    return (
        <div
            className={`px-3 py-2 rounded-lg flex border-border-white border-opacity-5 space-x-2 ${
                dark ? 'bg-level-three' : 'bg-level-two'
            }`}
        >
            <div className="relative w-12">
                <img
                    data-testid="liq-staking-icon-img-1"
                    src={input_token.logo}
                    alt={input_token.symbol}
                    height={30}
                    width={30}
                    className="absolute bottom-0"
                />
                <img
                    data-testid="liq-staking-icon-img-2"
                    src={second_input_token.logo}
                    alt={second_input_token.symbol}
                    height={30}
                    width={30}
                    className="absolute top-0 left-4"
                />
            </div>

            <div>
                <h2 className="lg:text-xl">
                    <strong data-testid="icon-symbol">
                        {input_token.symbol} / {second_input_token.symbol}
                    </strong>
                </h2>
                <p className="text-secondary text-xs">LIQUIDITY STAKE</p>
            </div>
        </div>
    );
};

export const Icon: React.FC<{
    pool: StakingPool;
    dark?: boolean;
    className?: string;
}> = ({ pool, dark = false, className }) => {
    const { input_token, reward_token, type } = pool || {};
    return (
        <div className={`flex space-x-2 ${className}`}>
            {type === StakingType.Liquidity ? (
                <LiquidityIconPart
                    pool={pool as LiquidityStakingPool}
                    dark={dark}
                />
            ) : (
                <IconPart
                    dark={dark}
                    img={input_token?.logo}
                    symbol={input_token?.symbol}
                    action="Stake"
                />
            )}

            <IconPart
                dark={dark}
                img={reward_token?.logo}
                symbol={reward_token?.symbol}
                action="Earn"
            />
        </div>
    );
};
