import React, { useState } from 'react';

import { InputProps } from '@src/ts/props';

import { Checkbox } from './Checkbox';
import { Label } from './Label';

import { containerClasses, inputClasses } from './util';

export const Input: React.FC<InputProps> = ({
    className,
    id,
    inputClassName,
    label,
    name,
    onChange,
    placeholder,
    type = 'text',
    value,
    error,
    inset,
    tooltip,
    step,
    min,
    loading,
    ...rest
}) => {
    const [focused, setFocused] = useState(false);

    if (type === 'checkbox') {
        return (
            <Checkbox
                id={id}
                label={label}
                tooltip={tooltip}
                error={error}
                placeholder={placeholder}
                name={name}
                value={value}
                onChange={onChange}
                className={className}
                {...rest}
            />
        );
    }

    const container_classes = containerClasses(
        inputClassName,
        focused,
        loading || !!inset,
    );
    const input_classes = inputClasses(focused, loading || !!inset);

    return (
        <div className={`${className || ''}`}>
            <div className="flex space-between items-center text-secondary">
                {label && <Label id={id} label={label} tooltip={tooltip} />}
                {!!error && <p className="text-red-500">{error}</p>}
            </div>
            <div className={container_classes}>
                <input
                    onFocus={() => setFocused(true)}
                    onBlur={() => {
                        setFocused(false);
                    }}
                    data-testid={id}
                    className={input_classes}
                    id={id}
                    name={name}
                    onChange={onChange}
                    type={type}
                    value={value}
                    placeholder={placeholder}
                    step={step}
                    min={min}
                    {...rest}
                />
                {loading && (
                    <div className="loader mr-4 mx-auto absolute right-0">
                        <img
                            src="/img/Spinner-Small.png"
                            className="mx-auto"
                            style={{
                                width: '20px',
                                height: '20px',
                            }}
                            alt="Loading..."
                        />
                    </div>
                )}
                {!loading && inset}
            </div>
        </div>
    );
};

export { CurrencyInput } from './CurrencyInput';
