import { StakingPool, StakingType } from '@src/ts/interfaces';
import { parseBalance } from '@src/utils/web3';

export const getPoolLabel = (pool: StakingPool): string =>
    `${pool.name} - ${
        pool.type === StakingType.NFT
            ? `${parseBalance(pool.reward_rate)} ${
                  pool.reward_token.symbol
              } p/day`
            : `${pool.reward_rate} APY`
    }${pool.type === StakingType.NFT ? '' : ` - ${pool.nft_name || 'No NFT'}`}`;
