import { useMemo, useState } from 'react';
import { useRouter } from 'next/router';

import { Switch } from '@src/components/Switch';
import { Dictionary, StakingType } from '@src/ts/interfaces';
import { PoolManagementProps } from '@src/ts/props';

import { BackButton } from '../BackButton';

import { NFTBoost } from './NFTBoost';
import { PoolForm } from './PoolForm';
import { NFTCustomisation } from './NFTCustomisation';

const getNavOpts = (type: StakingType) => {
    switch (type) {
        case StakingType.NFT:
            return ['Pool setup', 'Collection setup'];
        default:
            return ['Pool setup', 'NFT boost setup'];
    }
};

export const PoolManagement: React.FC<PoolManagementProps> = ({
    state = {},
    handleChange,
    handleNFTChange,
    handleCustomisationChange,
    add = false,
    handleClick,
    errors,
    loading,
    ticker,
    disabled,
    nft_has_changes,
    customisation_has_changes,
    idx,
}) => {
    const {
        query: { is_second_form },
    } = useRouter();

    const [nav, setNav] = useState(is_second_form === 'true' ? 1 : 0);

    const handleBack = () => setNav(0);

    const nav_opts = useMemo(
        () => getNavOpts(state.type as StakingType),
        [state.type],
    );

    return (
        <div className="max-w-xl mx-auto">
            <BackButton url={`/manage`} />

            <div className="bg-level-one rounded p-5 border border-custom mb-40">
                <Switch curr={nav} setCurr={setNav} opts={nav_opts} dark />

                {nav === 0 ? (
                    <PoolForm
                        state={state}
                        handleChange={handleChange}
                        add={add}
                        handleClick={handleClick}
                        errors={errors}
                        loading={loading}
                        disabled={disabled}
                        ticker={ticker}
                    />
                ) : state.type === StakingType.NFT ? (
                    <NFTCustomisation
                        add={add}
                        back={handleBack}
                        state={state['customisation'] as Dictionary}
                        handleChange={handleCustomisationChange}
                        input_token={state.input_token as string}
                        customisation_has_changes={customisation_has_changes}
                        idx={idx}
                        address={state.address as string}
                        type={state.type as StakingType}
                    />
                ) : (
                    <NFTBoost
                        add={add}
                        back={handleBack}
                        state={state['nft'] as Dictionary}
                        handleChange={handleNFTChange}
                        apy={(state?.reward as string) || '0'}
                        nft_has_changes={nft_has_changes}
                        idx={idx}
                        address={state.address as string}
                        type={state.type as StakingType}
                    />
                )}
            </div>
        </div>
    );
};
