import { useRef, useState, useEffect } from 'react';
import dayjs from 'dayjs';

import { useAppSelector } from '@src/hooks';
import { useWeb3Onboard } from '@src/hooks/useWeb3Onboard';
import { StakingType } from '@src/ts/interfaces';

import { getReward } from './utils';

export const useStakingPools = (initial: boolean, is_nft: boolean) => {
    const { account } = useWeb3Onboard();
    const { pools } = useAppSelector((state) => state.staking);
    const active_pools_ref = useRef(null);
    const executeScroll = () => active_pools_ref.current.scrollIntoView();
    const [display_graphic, setDisplayGraphic] = useState(false);
    const [has_stake, setHasStake] = useState([]);
    const [active_no_stake, setActiveNoStake] = useState([]);
    const [has_set_display_type, setHasSetDisplayType] = useState(false);

    useEffect(() => {
        const to_display = pools.filter(
            ({ type }) => (type === StakingType.NFT) === is_nft,
        );
        setHasStake(
            to_display
                .filter(({ has_stake }) => has_stake)
                .sort(
                    (a, b) =>
                        dayjs(a.last_user_deposit)
                            .add(Number(a.lock_period), 'days')
                            .unix() -
                        dayjs(b.last_user_deposit)
                            .add(Number(b.lock_period), 'days')
                            .unix(),
                ),
        );
        setActiveNoStake(
            to_display
                .filter(({ has_stake, end_date }) => {
                    return !has_stake && dayjs().isBefore(dayjs(end_date));
                })
                .sort((a, b) => getReward(b) - getReward(a)),
        );
    }, [pools, is_nft]);

    useEffect(() => {
        if (
            !initial &&
            pools.filter(({ type }) => type === StakingType.NFT).length >= 3 &&
            !has_set_display_type
        ) {
            setHasSetDisplayType(true);
            setDisplayGraphic(true);
        }
    }, [initial, pools, has_set_display_type]);

    return {
        account,
        active_no_stake,
        active_pools_ref,
        display_graphic,
        executeScroll,
        has_stake,
        setDisplayGraphic,
    };
};
