import { IS_DARK_MODE } from '@src/config';

const border = {
    true: 'border-secondary',
    false: 'border-custom',
};

const containerInset = (focused: boolean) => ({
    true: `bg-main shadow border shadow ${border[String(focused)]}`,
    false: '',
});

const inputInset = (focused: boolean) => ({
    true: '',
    false: `border shadow ${border[String(focused)]}`,
});

const dark_mode = { true: 'dark-mode', false: '' };

export const containerClasses = (
    inputClassName: string,
    focused: boolean,
    inset: boolean,
): string =>
    `relative flex items-center rounded  ${inputClassName || ''} ${
        containerInset(focused)[String(inset)]
    }`;

export const inputClasses = (focused: boolean, inset: boolean): string =>
    `focus:outline-none bg-main flex-1 w-full py-3 px-3 rounded dark-placeholder  ${
        dark_mode[IS_DARK_MODE]
    } ${inputInset(focused)[String(inset)]}`;
