import React from 'react';

import { NFTStakingPool, StakingPool, StakingType } from '@src/ts/interfaces';

import { DesktopPoolItem } from './DesktopPoolItem';
import { MobilePoolItem } from './MobilePoolItem';
import { NFTPoolItem } from './NFTPoolItem';

const PoolItem: React.FC<{
    pool: StakingPool;
    display_graphic?: boolean;
    admin?: boolean;
}> = ({ pool, display_graphic, admin }) => {
    const is_nft = pool.type === StakingType.NFT;
    return (
        <>
            <div className="inline lg:hidden">
                {is_nft ? (
                    <NFTPoolItem pool={pool as NFTStakingPool} admin={admin} />
                ) : (
                    <MobilePoolItem pool={pool} admin={admin} />
                )}
            </div>
            <div className="hidden lg:inline">
                {is_nft && display_graphic ? (
                    <NFTPoolItem pool={pool as NFTStakingPool} admin={admin} />
                ) : (
                    <DesktopPoolItem pool={pool} admin={admin} />
                )}
            </div>
        </>
    );
};

export default PoolItem;
