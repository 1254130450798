import { PlusIcon } from '@src/components/Icon';
import { IS_DARK_MODE } from '@src/config';
import { AddPoolButton } from '../AddPoolButton';

export const NoPools: React.FC = () => (
    <div className="text-center min-h-screen">
        <div
            className={
                'my-12 relative p-3 w-48 mx-auto aspect-square rounded-full flex items-center bg-gradient-to-tr from-level-one'
            }
        >
            <img
                src={`/icons/welcome-${IS_DARK_MODE ? 'dark' : 'light'}.svg`}
                alt="Welcome"
                className="mx-auto"
            />
            <div className="absolute p-3 -top-2 -right-2 bg-accent rounded-full border border-white border-opacity-5">
                <PlusIcon width={8} height={8} />
            </div>
        </div>

        <h4 className="text-2xl max-w-sm mx-auto">
            <strong>Welcome to your new staking management panel</strong>
        </h4>
        <p className="mt-4 mb-8 text-secondary">
            Let’s get started by creating your first staking pool
        </p>

        <AddPoolButton className="mx-auto" />
    </div>
);
