import { ConnectWallet, NoStake, NoActivePools, StakedInAll } from './Cards';

import { DisplayButtons } from './DisplayButtons';
import { useStakingPools } from './hooks';
import PoolItem from './PoolItem';
import { PoolsContainer } from './PoolContainer';
import { PoolLoadingWrapper } from './PoolsLoading';

export const StakingPools: React.FC<{ initial: boolean; is_nft: boolean }> = ({
    initial,
    is_nft,
}) => {
    const {
        account,
        active_no_stake,
        active_pools_ref,
        display_graphic,
        executeScroll,
        has_stake,
        setDisplayGraphic,
    } = useStakingPools(initial, is_nft);

    return (
        <div className="my-8">
            <PoolsContainer
                title="Staked pools"
                is_nft={is_nft}
                display_graphic={display_graphic}
                has_pools={has_stake.length > 0}
                is_active={true}
                button={
                    is_nft && (
                        <DisplayButtons
                            display_graphic={display_graphic}
                            setDisplayGraphic={setDisplayGraphic}
                        />
                    )
                }
            >
                <PoolLoadingWrapper loading={initial}>
                    {has_stake.length > 0 ? (
                        has_stake.map((p) => (
                            <PoolItem
                                pool={p}
                                key={p.id}
                                display_graphic={display_graphic}
                            />
                        ))
                    ) : account ? (
                        <NoStake executeScroll={executeScroll} />
                    ) : (
                        <ConnectWallet />
                    )}
                </PoolLoadingWrapper>
            </PoolsContainer>

            <div ref={active_pools_ref}>
                <PoolsContainer
                    title="Active pools"
                    is_nft={is_nft}
                    display_graphic={display_graphic}
                    has_pools={active_no_stake.length > 0}
                >
                    <PoolLoadingWrapper loading={initial}>
                        {active_no_stake.length > 0 ? (
                            active_no_stake.map((p) => (
                                <PoolItem
                                    pool={p}
                                    key={p.id}
                                    display_graphic={display_graphic}
                                />
                            ))
                        ) : has_stake.length > 0 ? (
                            <StakedInAll />
                        ) : (
                            <NoActivePools />
                        )}
                    </PoolLoadingWrapper>
                </PoolsContainer>
            </div>
        </div>
    );
};
