import { Tooltip } from '@src/components/Tooltip';

export type InputType = 'number' | 'text' | 'datetime-local';

export const Label: React.FC<{
    label: string;
    id: string;
    tooltip?: string;
}> = ({ id, label, tooltip }) => (
    <div className="flex-1 text-sm mb-2 flex items-center flex-wrap space-x-2">
        <label htmlFor={id}>{label}</label>
        {tooltip && (
            <Tooltip text={tooltip}>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6 text-accent"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                >
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                </svg>
            </Tooltip>
        )}
    </div>
);
