export const PlusIcon: React.FC<{ width?: number; height?: number }> = ({
    width = 4,
    height = 4,
}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        className={`h-${height} w-${width}`}
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
    >
        <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M12 4v16m8-8H4"
        />
    </svg>
);
