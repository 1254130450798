import { AnalyticsTextItem } from './Item';
import { useStakingAnalytics } from './hooks';

export const StakingAnalytics: React.FC = () => {
    const items = useStakingAnalytics();

    return (
        <div className="bg-level-one rounded border border-custom space-y-4 lg:space-y-0 lg:flex lg:space-x-12 p-5">
            {items.map(({ text, title }) => (
                <AnalyticsTextItem key={title} title={title}>
                    {text}
                </AnalyticsTextItem>
            ))}
        </div>
    );
};
