import React, { useState } from 'react';

import { NFTStakingPool } from '@src/ts/interfaces';

import { useInfoItems, usePoolItem } from './hooks';

const PoolTextItem: React.FC<{ title: string; right: boolean }> = ({
    children,
    title,
    right,
}) => (
    <div className={`${right ? 'text-right' : ''} my-2`}>
        <h3 className="text-lg">
            <strong>{children}</strong>
        </h3>
        <p className="text-xs text-secondary">{title.toUpperCase()}</p>
    </div>
);

export const NFTPoolItem: React.FC<{
    pool: NFTStakingPool;
    admin?: boolean;
}> = ({ pool, admin }) => {
    const { handleClick, loading, lock_end_date, pool_time } = usePoolItem(
        pool,
        admin,
    );
    const [loaded, setLoaded] = useState(false);
    const items = useInfoItems(pool, lock_end_date, pool_time, admin);

    return (
        <div
            className={`my-4 w-full text-left rounded border border-custom cursor-pointer bg-level-one text-primary ${
                loading ? 'animate-pulse' : ''
            }`}
            onClick={handleClick}
        >
            <div
                className={`relative w-full rounded-t ${
                    !loaded && !loading ? 'animate-pulse' : ''
                } ${!loaded ? 'bg-main aspect-square' : ''}`}
            >
                <img
                    src={pool.logo}
                    alt={pool.name}
                    onLoad={() => setLoaded(true)}
                    className="w-full h-full rounded-t border border-custom relative"
                />

                <h3 className="text-lg absolute bottom-1 left-2">
                    <strong>{pool.name}</strong>
                </h3>
            </div>

            <div className="p-4 grid grid-cols-2">
                {items.map(({ text, title }, idx) => (
                    <PoolTextItem
                        title={title}
                        key={title}
                        right={idx % 2 === 1}
                    >
                        {text}
                    </PoolTextItem>
                ))}
            </div>
        </div>
    );
};
