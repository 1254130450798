import React from 'react';

import { Tooltip } from '..';

export const InfoText: React.FC<{
    text: string;
    info: string;
    text_color?: string;
    className?: string;
}> = ({ info, text, text_color, className }) => {
    return (
        <div className={`flex items-center flex-wrap space-x-2 ${className}`}>
            <p className={text_color}>{text}</p>

            <Tooltip text={info}>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6 text-accent"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                >
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                </svg>
            </Tooltip>
        </div>
    );
};
