import React, { useMemo, useState } from 'react';
import dayjs from 'dayjs';

import { StakingPool, StakingType } from '@src/ts/interfaces';
import { NoAdminPools } from '@src/components/Staking/StakingPools/Cards';
import { PoolLoadingWrapper } from '@src/components/Staking/StakingPools/PoolsLoading';
import PoolItem from '@src/components/Staking/StakingPools/PoolItem';
import { PoolsContainer } from '@src/components/Staking/StakingPools/PoolContainer';

import { RoutingButtons } from '../RoutingButtons';
import { Switch } from '../..';
import { HAS_BOTH_STAKING_LAYOUTS, STAKING_OPTIONS } from '@src/constants';
import { getInitialStakingType } from '@src/utils/staking';

export const StakingPools: React.FC<{
    pools: StakingPool[];
    initial: boolean;
}> = ({ pools, initial }) => {
    const [type, setType] = useState(getInitialStakingType());
    const is_nft = type === 1;

    const { active, expired } = useMemo(
        () =>
            pools
                .filter(({ type }) =>
                    is_nft
                        ? type === StakingType.NFT
                        : type !== StakingType.NFT,
                )
                .reduce(
                    (acc, curr) => {
                        if (dayjs().isBefore(dayjs(curr.end_date))) {
                            return { ...acc, active: [...acc.active, curr] };
                        } else {
                            return { ...acc, expired: [...acc.expired, curr] };
                        }
                    },
                    { active: [], expired: [] },
                ),
        [pools, is_nft],
    );

    return (
        <div className="my-8">
            <div className="lg:flex flex-row-reverse items-center space-y-4 lg:space-y-0">
                <RoutingButtons />
                {HAS_BOTH_STAKING_LAYOUTS && (
                    <div className="flex-1">
                        <Switch
                            curr={type}
                            opts={STAKING_OPTIONS}
                            setCurr={(n) => setType(n)}
                            className="w-full lg:w-80"
                        />
                    </div>
                )}
            </div>

            <PoolsContainer title="Active pools" is_nft={is_nft} is_admin>
                <PoolLoadingWrapper loading={initial}>
                    {active.length > 0 ? (
                        active.map((p) => (
                            <PoolItem pool={p} key={p.id} admin={true} />
                        ))
                    ) : (
                        <NoAdminPools type="active" />
                    )}
                </PoolLoadingWrapper>
            </PoolsContainer>
            <PoolsContainer title="Expired pools" is_admin is_nft={is_nft}>
                <PoolLoadingWrapper loading={initial}>
                    {expired.length > 0 ? (
                        expired.map((p) => (
                            <PoolItem pool={p} key={p.id} admin={true} />
                        ))
                    ) : (
                        <NoAdminPools type="expired" />
                    )}
                </PoolLoadingWrapper>
            </PoolsContainer>
        </div>
    );
};
