import React from 'react';

import { Button } from '@src/components/Button';

export const RequiresSetup: React.FC<{ back: () => void; text: string }> = ({
    back,
    text,
}) => {
    return (
        <div className="text-center px-4">
            <h2 className="text-2xl mt-12">
                <strong>{text}</strong>
            </h2>

            <Button className="w-48 mt-12" dark onClick={back}>
                Back
            </Button>
        </div>
    );
};
