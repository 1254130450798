import React, { useMemo } from 'react';

import { Button } from '@src/components/Button';
import { Input } from '@src/components/Input';
import { PoolFormProps } from '@src/ts/props';
import { getPoolFormData } from '@src/utils/form';
import { Select } from '@src/components/Select';
import { ModuleWithIdx, StakingModule, StakingType } from '@src/ts/interfaces';
import { shortenHex } from '@src/utils/web3';
import { getAddressOptions } from '@src/utils/staking';
import { HAS_BOTH_STAKING_LAYOUTS } from '@src/constants';
import { Switch } from '@src/components';
import { STAKING_MODULES } from '@src/config';

const Warning: React.FC<{ type: StakingType }> = ({ type }) => {
    return (
        <div className="border border-secondary rounded p-4 mt-8">
            <h4>
                <strong>Important!</strong>
            </h4>

            <ul className="ml-4 text-sm text-secondary space-y-2 mt-4 mb-2">
                <li>
                    {type === StakingType.NFT
                        ? 'You need to set up the collection information after creating the pool'
                        : 'You need to set up and create a pool before you can set up the NFT boost'}
                </li>

                <li>
                    These parameters interact with a smart contract. Each change
                    will require to complete a transaction to save changes.
                </li>
            </ul>
        </div>
    );
};

const options = STAKING_MODULES.reduce(
    (types, { type }) => (types.includes(type) ? types : [...types, type]),
    [],
);

export const PoolForm: React.FC<PoolFormProps> = ({
    state = {},
    handleChange, // should take name + value
    add = false,
    handleClick,
    errors,
    loading,
    disabled,
    ticker,
}) => {
    const pool_form_data = useMemo(
        () =>
            getPoolFormData(
                ticker || 'XXX',
                state.reward as string,
                state.type as StakingType,
            ),
        [state.type, state.reward, ticker],
    );

    const opts = useMemo(
        () => getAddressOptions(state.type as StakingType),
        [state.type],
    );

    return (
        <div className="mt-8">
            {add && HAS_BOTH_STAKING_LAYOUTS && (
                <div className="my-4">
                    <p className="text-secondary text-sm mb-2">Staking type</p>
                    {
                        <Switch
                            opts={options}
                            curr={options.findIndex((o) => state.type === o)}
                            setCurr={(curr) =>
                                handleChange('type', options[curr])
                            }
                            dark
                        />
                    }
                </div>
            )}

            {add && opts.length > 1 && (
                <Select
                    options={opts}
                    selected={state.address as string}
                    getId={(m: StakingModule) => m.address}
                    getLabel={(m: ModuleWithIdx) =>
                        `${m.type} ${m.idx + 1} - ${shortenHex(m.address, 4)} ${
                            m.idx === opts.length - 1 ? '(latest)' : ''
                        }`
                    }
                    onSelect={(id) => handleChange('address', id)}
                    label={'Staking contract'}
                    placeholder="Select contract"
                    className="my-2"
                    id="staking-pool"
                />
            )}

            {pool_form_data.map(
                ({ name, label, type, placeholder, tooltip, inset, step }) => (
                    <Input
                        className={`my-4`}
                        key={name}
                        value={state[name] as string}
                        name={name}
                        id={name}
                        label={label}
                        error={errors[name] as string}
                        onChange={(e) =>
                            handleChange(
                                e.target.name,
                                e.target.type === 'checkbox'
                                    ? e.target.checked
                                    : e.target.value,
                            )
                        }
                        placeholder={placeholder}
                        type={type}
                        inset={
                            inset && (
                                <div className="px-4">
                                    <strong>{inset}</strong>
                                </div>
                            )
                        }
                        tooltip={tooltip}
                        step={step}
                    />
                ),
            )}

            {add && <Warning type={state.type as StakingType} />}

            <Button
                id="add_pool"
                className={'w-full mt-8 mb-4'}
                onClick={handleClick}
                loading={loading}
                disabled={disabled || loading}
            >
                {add ? 'Add Pool' : 'Save changes'}
            </Button>
        </div>
    );
};
