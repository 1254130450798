import React from 'react';

import { Progress } from '@src/components/Progress';
import { formatUnits, parseEther } from '@ethersproject/units';
import { BigNumber } from '@ethersproject/bignumber';
import { formatBigNumber } from '@src/utils/format';

export const ProgressBar: React.FC<{
    hardcap: string;
    total_staked: string;
    second_total_staked: string;
    decimals: number;
    second_decimals: number;
    symbol: string;
    second_symbol: string;
    liquidity: boolean;
}> = ({
    hardcap,
    total_staked,
    decimals,
    symbol,
    liquidity,
    second_total_staked,
    second_decimals,
    second_symbol,
}) => {
    const total =
        BigNumber.from(hardcap).gt(0) &&
        BigNumber.from(total_staked).gt(hardcap)
            ? hardcap
            : total_staked;

    const percent = Math.round(
        (Number(formatUnits(total, decimals)) /
            Number(formatUnits(hardcap, decimals))) *
            100,
    );

    const is_unlimited =
        liquidity || hardcap === parseEther('1000000000').toString(); // TODO: maybe use input token max supply?

    return (
        <div>
            <div className="text-sm flex mb-1">
                <div className="lg:hidden flex-1 text-secondary">Capacity</div>
                <div>
                    <h5>
                        {`${formatBigNumber(
                            total,
                            decimals,
                            decimals === 0 ? 0 : 2,
                        )} / ${
                            !is_unlimited
                                ? `${formatBigNumber(
                                      hardcap,
                                      decimals,
                                      !decimals ? 0 : 2,
                                  )}`
                                : '∞'
                        } ${(!!decimals && symbol) || ''}`}
                    </h5>
                    {liquidity && (
                        <h5>
                            {`${formatBigNumber(
                                second_total_staked,
                                second_decimals,
                                2,
                            )} / ∞ ${second_symbol}`}
                        </h5>
                    )}
                </div>
            </div>

            {!is_unlimited && (
                <div className="flex items-center space-x-2">
                    <div className="flex-1 lg:flex-none lg:w-32">
                        <Progress percent={percent} height={'h-2'} />
                    </div>
                    <div className="text-primary">{percent}%</div>
                </div>
            )}
        </div>
    );
};
