import { FormData, StakingType } from '@src/ts/interfaces';
import { PoolForm } from '@src/ts/props';
import { effectiveApy } from './staking';

export const getPoolFormData = (
    ticker: string,
    apy: string,
    pool_type: StakingType,
): FormData[] =>
    [
        {
            label: `${address_types[pool_type]} address`,
            name: 'input_token',
            type: 'text',
            placeholder: 'Enter the token address',
            tooltip: input_tooltips[pool_type],
            required: true,
            types: [StakingType.Compound, StakingType.Liquidity],
        },
        {
            label: 'NFT Address',
            name: 'input_token',
            type: 'text',
            placeholder: 'Enter the NFT address',
            tooltip:
                'The contract address of the NFT(s) to be used for staking',
            required: true,
            types: [StakingType.NFT],
        },
        {
            label: 'Reward token',
            name: 'reward_token',
            type: 'text',
            placeholder: 'Enter the reward token address',
            tooltip:
                'The contract address of the token to be used for paying out rewards',
            required: true,
            types: [StakingType.NFT, StakingType.Liquidity],
        },
        {
            label: 'Pool capacity',
            name: 'hardcap',
            type: 'number',
            placeholder: 'Enter pool capacity',
            inset: pool_type !== StakingType.NFT && ticker,
            tooltip: 'The maximum number of tokens that can be staked',
            required: true,
            types: [StakingType.Compound, StakingType.NFT],
        },
        {
            label: 'APY',
            name: 'reward',
            type: 'number',
            placeholder: 'Enter pool APY',
            inset:
                pool_type === StakingType.Compound
                    ? `~ ${(
                          effectiveApy(parseFloat(apy || '0') / 100, 365) * 100
                      ).toFixed(2)} %`
                    : '%',
            tooltip: 'The annual percentage reward for the pool',
            step: 0.1,
            required: true,
            types: [StakingType.Compound, StakingType.Liquidity],
        },
        {
            label: 'Reward rate',
            name: 'reward',
            type: 'number',
            placeholder: 'Enter pool rewerd rate',
            inset: ticker,
            tooltip: 'The daily reward rate per NFT',
            step: 0.01,
            required: true,
            types: [StakingType.NFT],
        },
        {
            label: 'Lock period',
            name: 'lock_period',
            type: 'number',
            placeholder: 'Enter pool lock days',
            inset: 'days',
            tooltip: 'The number of days a users stake will be locked for',
            step: 1,
            min: 1,
            required: true,
            types: [
                StakingType.Compound,
                StakingType.NFT,
                StakingType.Liquidity,
            ],
        },
        {
            label: 'Pool expiration date',
            name: 'end_date',
            tooltip: 'The date at which users can no longer stake tokens',
            type: 'datetime-local',
            required: true,
            types: [
                StakingType.Compound,
                StakingType.NFT,
                StakingType.Liquidity,
            ],
        },
        {
            placeholder: 'Withdraw locked',
            name: 'is_withdraw_locked',
            tooltip:
                'Are users stake locked or can they withdraw at any time (sacrificing rewards)',
            type: 'checkbox',
            required: true,
            types: [StakingType.NFT, StakingType.Liquidity],
        },
    ].filter(({ types }) => types.includes(pool_type)) as FormData[];

export const getNFTFormData = (
    boost_percent: string,
    activated: boolean,
): FormData[] => [
    {
        label: 'Contract address',
        name: 'contract',
        type: 'text',
        tooltip: 'The contract address of the required NFT',
        placeholder: 'Enter the NFT contract address',
        required: true,
    },
    {
        label: 'NFT name',
        name: 'name',
        type: 'text',
        tooltip:
            'The name of the required NFT that will be displayed to the users',
        placeholder: 'Enter the required NFT name',
        required: true,
    },
    {
        label: 'Boost multilpier',
        name: 'multiplier',
        type: 'number',
        placeholder: '0',
        inset: boost_percent,
        step: 0.1,
        tooltip:
            'The multiplier used for calculating NFT boost. Supports 1 decimal place',
        required: true,
        min: 1,
    },
    {
        label: 'First token ID to include',
        name: 'start_idx',
        type: 'number',
        placeholder: '0',
        tooltip: 'First token ID to include',
        step: 1,
        required: true,
        min: 0,
    },
    {
        label: 'Last token ID to include',
        name: 'end_idx',
        type: 'number',
        placeholder: '0',
        tooltip: 'Last token ID to include',
        step: 1,
        required: true,
        min: 0,
    },
    {
        label: 'NFT Boost status',
        name: 'active',
        type: 'checkbox',
        required: true,
        placeholder: `NFT boost is ${activated ? '' : 'not '}activated`,
        min: 1,
    },
];

export const getNFTCustomisationFormData = (): FormData[] => [
    {
        label: 'Collection name',
        name: 'name',
        type: 'text',
        tooltip:
            'The name of the staking pool that will be displayed to the users (collection/sub-collection name)',
        placeholder: 'Enter the collection name',
        required: true,
    },
    {
        label: 'Collection logo URL',
        name: 'logo',
        type: 'text',
        tooltip:
            'The icon for the staking pool that will be displayed to the users (collection/sub-collection logo)',
        placeholder: 'Enter the collection logo URL',
        required: true,
    },
    {
        label: 'Drawer header logo URL',
        name: 'header_logo',
        type: 'text',
        tooltip: 'The image used for the staking drawer header for this pool',
        placeholder: 'Enter the header logo URL',
        required: true,
    },
    {
        label: 'First token ID to include',
        name: 'start_idx',
        type: 'number',
        placeholder: '0',
        tooltip:
            'First token ID to include in the collection (default 0 for entire collection)',
        step: 1,
        required: true,
        min: 0,
    },
    {
        label: 'Last token ID to include',
        name: 'end_idx',
        type: 'number',
        placeholder: '0',
        tooltip:
            'Last token ID to include in the collection (default total supply for the entire collection - note it does not include NFTs that are not already minted i.e max supply of contract)',
        step: 1,
        required: true,
        min: 0,
    },
    {
        label: 'Max per user',
        name: 'max_per_user',
        type: 'number',
        tooltip: 'The hardcap per user of the pool',
        step: 1,
        required: true,
    },
    {
        label: 'Collection URL',
        name: 'collection_url',
        type: 'text',
        tooltip: 'A link to where users can aquire the required NFT',
        placeholder: 'Enter the collection URL',
        required: true,
    },
];
export const validateInput = (
    form_data: FormData[],
    state: { [key: string]: unknown },
): { has_required_fields: boolean; errors: PoolForm } => {
    const errors = {} as PoolForm;
    const has_required_fields =
        form_data.filter(({ name, label, required }) => {
            if (
                required &&
                [null, undefined, ''].includes(state[name] as string)
            ) {
                errors[name] = label + ' required';
                return false;
            }
            return true;
        }).length === form_data.length;

    return { has_required_fields, errors };
};

const address_types = {
    [StakingType.Compound]: 'Token',
    [StakingType.Liquidity]: 'Pair',
};

const input_tooltips = {
    [StakingType.Compound]:
        'The contract address of the token to be used for staking',
    [StakingType.Liquidity]:
        'The pair address of the liquidity pool to be used for staking',
};
