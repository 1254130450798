import React, { useMemo } from 'react';

import {
    LiquidityStakingPool,
    StakingPool,
    StakingType,
} from '@src/ts/interfaces';

import { TokenImage } from './TokenImage';

const getTokenTypes = (pool) => {
    const { type, input_token, reward_token } = pool;
    switch (type) {
        case StakingType.NFT:
            return [reward_token];
        case StakingType.Liquidity:
            return [
                input_token,
                reward_token,
                (pool as LiquidityStakingPool).second_input_token,
            ];
        default:
            return [input_token, reward_token];
    }
};

export const TokenImageSetup: React.FC<{ pools: StakingPool[] }> = ({
    pools,
}) => {
    const pool_tokens = useMemo(
        () =>
            pools.reduce((acc, pool) => {
                getTokenTypes(pool).forEach((token) => {
                    if (
                        !acc.find(
                            ({ address }) =>
                                address.toLowerCase() ===
                                token.address.toLowerCase(),
                        )
                    ) {
                        acc.push(token);
                    }
                });
                return acc;
            }, []),
        [pools],
    );

    return (
        <div className="max-w-2xl mx-auto my-20">
            <h3 className="text-xl mb-8">
                <strong>Token image setup</strong>
            </h3>

            <div className="p-4 rounded bg-level-one border border-custom space-y-2">
                {pool_tokens.map((token) => (
                    <TokenImage key={token.address} {...token} />
                ))}
            </div>
        </div>
    );
};
