import React from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';

import { SettingsIcon } from '@src/components/Icon';

import { AddPoolButton } from '../AddPoolButton';

export const RoutingButtons: React.FC = () => {
    const router = useRouter();
    const route = router.pathname.includes('settings')
        ? `/manage`
        : `/manage/settings`;

    return (
        <div className="flex space-x-4 items-center">
            <Link href={route}>
                <a>
                    <button className="p-2 bg-level-one rounded border border-custom  text-secondary hover:text-primary">
                        <SettingsIcon />
                    </button>
                </a>
            </Link>
            <AddPoolButton />
        </div>
    );
};
