import { removeCommas } from '@src/utils/web3';

import { parseUnits } from '@ethersproject/units';
import {
    LiquidityStakingPool,
    StakingPool,
    StakingType,
} from '@src/ts/interfaces';
import { unstake_field, STAKE_METHOD, UNSTAKE_METHOD } from './constants';
import { BigNumber } from '@ethersproject/bignumber';

export const getStakeArgs = (
    pool: StakingPool,
    deposit_amount: string,
    second_deposit_amount: string,
    selected_ids: number[],
): [string, unknown[]] => {
    return [
        STAKE_METHOD[pool.type],
        stakeArgs(pool, deposit_amount, second_deposit_amount, selected_ids),
    ];
};

export const getUnstakeArgs = (
    pool: StakingPool,
    selected_ids: number[],
    withdraw_percent: number,
): [string, unknown[]] => {
    return [
        UNSTAKE_METHOD[pool.type],
        unstakeArgs(pool, selected_ids, withdraw_percent),
    ];
};

const stakeArgs = (
    pool: StakingPool,
    deposit_amount: string,
    second_deposit_amount: string,
    selected_ids: number[],
) => {
    const parsed_deposit = parseUnits(
        removeCommas(deposit_amount || '0'),
        pool.input_token.decimals,
    );

    switch (pool.type) {
        case StakingType.NFT:
            return [pool.contract_idx, selected_ids, {}];
        case StakingType.Compound:
            return [pool.contract_idx, parsed_deposit, {}];
        case StakingType.Liquidity:
            return getLiquidityPoolArgs(
                pool as LiquidityStakingPool,
                parsed_deposit,
                second_deposit_amount,
            );
    }
};

const getLiquidityPoolArgs = (
    pool: LiquidityStakingPool,
    parsed_deposit: BigNumber,
    second_deposit_amount: string,
) => {
    const parsed_deposit_token0 = parseUnits(
        removeCommas(second_deposit_amount || '0'),
        pool.second_input_token.decimals,
    );

    const { is_native = [false, 0] } = pool;

    return [
        pool.contract_idx,
        parsed_deposit,
        parsed_deposit_token0,
        {
            value:
                (is_native[0] &&
                    (is_native[1] === 0
                        ? parsed_deposit
                        : parsed_deposit_token0)) ||
                '0',
        },
    ];
};

const unstakeArgs = (
    pool: StakingPool,
    selected_ids: number[],
    withdraw_percent: number,
) => {
    const field = unstake_field[pool.type];

    switch (pool.type) {
        case StakingType.NFT:
            return [pool.contract_idx, selected_ids];
        case StakingType.Compound:
            return [pool.contract_idx, pool[field]];
        case StakingType.Liquidity:
            return [
                pool.contract_idx,
                BigNumber.from(pool[field]).mul(withdraw_percent).div(100),
            ];
    }
};

export const getPoolMultiplier = (pool: StakingPool, admin = false) => {
    const base = pool.type === StakingType.Compound ? 10 : 100;
    const {
        user_multiplier = base.toString(),
        nft_multiplier = base,
        nft_multiplier_used,
        reward_rate,
    } = pool;

    const boost =
        Math.round(
            ((parseFloat(reward_rate) * nft_multiplier) / base -
                parseFloat(reward_rate)) *
                base,
        ) / base;

    const has_boost = admin
        ? nft_multiplier_used
        : nft_multiplier_used &&
          BigNumber.from(user_multiplier).eq(nft_multiplier);

    return { boost, has_boost };
};
